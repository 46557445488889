import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { IonRouterOutlet, LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { CitLoadingController } from 'src/app/shared/loading.service';
import { ToastUtils } from 'src/app/shared/toast-utils';
import { environment } from 'src/environments/environment';
import { StartRegistrationAction } from '../state/registration.actions';
import { RegistrationState } from '../state/registration.state';
import { CredentialsVault } from '../utils/credentialsVault.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  public signupForm: FormGroup;
  private loader?: any;

  constructor(
      @Optional() private routerOutlet: IonRouterOutlet,
      private navCtrl: NavController,
      public formBuilder: FormBuilder,
      private store: Store,
      private translateService: TranslateService,
      private router: Router,
      private toastCtrl: ToastController,
      public loadingController: CitLoadingController,
      private emailComposer: EmailComposer,
      private platform: Platform,
      private credentialsVault: CredentialsVault,
    ) { }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  async submit() {
    if(!this.signupForm.valid) {
      this.signupForm.markAllAsTouched();
      return;
    }
    await this.showLoading();
    this.store.dispatch(new StartRegistrationAction(
      this.signupForm.value.username,
      this.signupForm.value.password
    )).subscribe(() => {
      this.loader?.dismiss();
      this.saveUsernameForAutofill();
      this.router.navigateByUrl('/complete-registration');
    }, (error) => {
      this.loader?.dismiss();
      this.showAuthError(error);
    });
  }

  alreadyRegistered() {
    if (this.routerOutlet && this.routerOutlet.canGoBack()) {
      this.navCtrl.setDirection('back');
      this.routerOutlet.pop();
    } else {
      this.navCtrl.navigateBack('/signin');
    }
  }

  async showAuthError(error) {
    const message = await this.translateService.get('login.error_generic').toPromise();
    const toast = await this.toastCtrl.create({
      ...ToastUtils.baseError,
      message
    });
    await toast.present();
  }

  async showLoading() {
    this.loader = await this.loadingController.createLoding();
    this.loader.present();
  }

  async forgotPassword() {
    const mail = environment.supportEmail;
    const subject = await this.translateService.get('email.forgot_subject').toPromise();
    const body = await this.translateService.get('email.forgot_body').toPromise();

    if (!this.platform.platforms().includes('capacitor')) {
      window.open(`mailto:${mail}?subject=${subject}&body=${body}`);
    } else {
      const email = {
        to: mail,
        subject,
        body,
        isHtml: true
      };
      // Send a text message using default options
      this.emailComposer.open(email);
    }
  }

  saveUsernameForAutofill() {
    const username = this.store.selectSnapshot(RegistrationState.username);
    if (username) {
      this.credentialsVault.saveUsername(username);
    }
  }
}
