import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CitLoadingController {
  constructor(
    private loadingController: LoadingController,
    private translateService: TranslateService
  ) {}

  async createLoding(opts?: any): Promise<any> {
    const actualMessage = opts?.message || await this.translateService.get('generic.wait').toPromise();
    return this.loadingController.create({
      message: actualMessage
    });
  }
}
