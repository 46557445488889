import { ModalOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HelpComponent } from '../shared/help/help.component';

export class HomeModals {
  public static async nfcTroubleModal(translateService: TranslateService): Promise<ModalOptions> {
    const title = await translateService.get('profile.nfc_problem_title').toPromise();
    const cta = await translateService.get('profile.nfc_problem_button').toPromise();
    const secondCta = await translateService.get('generic.cancel').toPromise();
    return {
      component: HelpComponent,
      componentProps: {
        title,
        text: '',
        imgSrc: 'assets/img/contatta-citizen.png',
        cta,
        secondCta
      },
      backdropDismiss: false,
      showBackdrop: true,
    };
  }

  public static async nfcTroubleWrite(translateService: TranslateService): Promise<ModalOptions> {
    const title = await translateService.get('profile.nfc_problem_home_title').toPromise();
    const cta = await translateService.get('profile.nfc_problem_home_button').toPromise();
    const secondCta = await translateService.get('generic.cancel').toPromise();
    return {
      component: HelpComponent,
      componentProps: {
        title,
        text: '',
        imgSrc: 'assets/img/contatta-citizen.png',
        cta,
        secondCta
      },
      backdropDismiss: false,
      showBackdrop: true,
    };
  }
}
