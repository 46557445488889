import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { IonicModule } from '@ionic/angular';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { HelpComponent } from './help/help.component';
import { TranslateModule } from '@ngx-translate/core';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';

@NgModule({
  declarations: [
    BarcodeScannerComponent,
    HelpComponent,
    ShowHidePasswordComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  exports: [
    BarcodeScannerComponent,
    HelpComponent,
    ShowHidePasswordComponent,
  ],
  providers: [
    Dialogs
  ]
})
export class SharedModule { }
