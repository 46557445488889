<div class="backdrop">
  <img class="backdrop-image">
  <div class="backdrop-solid"></div>
  <div class="backdrop-gradient"></div>
</div>
<ion-content>
  <form novalidate [formGroup]="loginForm" (ngSubmit)="submit()">
    <div class="container page-content">
      <div class="banner">
        <img class="logo"
              alt="Citizen logo"
              src="/assets/img/citizen.png">
        <div class="name citizen-bold-label">{{ 'login.title' | translate }}</div>
      </div>
      <div class="inputs">
        <div class="citizen-label">{{ 'generic.username' | translate }}</div>
        <ion-input type="text"
                    class="username-input"
                    formControlName="username"
                    placeholder="{{ 'generic.username_placeholder' | translate }}"
                    required
                    autocomplete="username"
                    ngDefaultControl></ion-input>
        <div class="citizen-label">{{ 'generic.password' | translate }}</div>
        
        <app-show-hide-password>
          <ion-input type="password"
                    formControlName="password"
                    placeholder="{{ 'generic.password_placeholder' | translate }}"
                    required
                    autocomplete="password"
                    ngDefaultControl></ion-input>
        </app-show-hide-password>
        
        <button class="forgot-password"
                type="button"
                (click)="forgotPassword()">{{ 'login.help' | translate }} <u>{{ 'login.help_click' | translate }}</u></button>
      </div>
      <div class="controls">
        <button class="login-button" 
                type="button"
                (click)="submit()">{{ 'login.login_button' | translate }}</button>
        <div class="citizen-label first-access-label">{{ 'login.first_time' | translate }}</div>
        <button class="auth-button"
                type="button"
                (click)="firstTimeLogin()">{{ 'login.firts_time_button' | translate }}</button>
      </div>
    </div>
  </form>
</ion-content>