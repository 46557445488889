import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CredentialsVault {
  saveUsername(username: string) {
    localStorage.lastUsedUsername = username;
  }

  getUsername(): string | undefined {
    return localStorage.lastUsedUsername;
  }
}
