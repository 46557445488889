<div class="help-container">
  <div class="help-img">
    <img src="{{ imgSrc }}" alt="scan product">
  </div>
  <div class="help-title citizen-bold-label">{{title}}</div>
  <div class="help-text citizen-light-label" [innerHTML]="text"></div>
  <button class="citizen-button help-button"
          (click)="close($event)">{{ cta }}</button>
  <button *ngIf="secondCta" class="second-help-button"
          (click)="secondClose($event)">{{ secondCta }}</button>
</div>