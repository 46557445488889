import { ModalOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HelpComponent } from 'src/app/shared/help/help.component';
import { formatString } from 'typescript-string-operations';

export class SignInUtils {
    public static async existingSession(translateService: TranslateService, session: any): Promise<ModalOptions> {
        console.log(session);
        const startedAt = session.started_at || '-';
        // The date from the server has Z at the and, we parse it as UTC
        const startedAtDate = new Date(
            Date.UTC(
                startedAt.substr(0, 4),
                startedAt.substr(5, 2) - 1,
                startedAt.substr(8, 2),
                startedAt.substr(11, 2),
                startedAt.substr(14, 2),
                startedAt.substr(17, 2)
              )
            );
        const platform: string = session.device ?? '';
        const title = await translateService.get('login.session_error_title').toPromise();
        const text = await translateService.get('login.session_error_text').toPromise();
        const cta = await translateService.get('login.session_error_cta').toPromise();
        const cancel = await translateService.get('generic.cancel').toPromise();
        const fullText = formatString(text, startedAtDate.toLocaleDateString() + ' ' + startedAtDate.toLocaleTimeString(), platform);
        return {
            component: HelpComponent,
            componentProps: {
                title,
                text: fullText,
                imgSrc: 'assets/img/gestione-operatori-elimina.png',
                cta,
                secondCta: cancel
            },
            backdropDismiss: false,
            showBackdrop: true
        };
    }
}
