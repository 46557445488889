import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {}

  me(): Observable<any> {
    return this.http.get(environment.baseUrl + '/authentication/me', {
      withCredentials: true
    });
  }

  login(username: string, password: string, platform: string, force: boolean): Observable<any> {
    return this.http.post(environment.baseUrl + '/authentication/log-in', {
      username,
      password,
      platform
    }, {
      headers: {
        'force': force ? 'true' : 'false'
      },
      withCredentials: true,
    });
  }

  /**
   * Logs in against citizen existing user base and returns a cardCode,
   * it is needed for the registration
   */
  citizenLogin(username: string, password: string): Observable<any> {
    return this.http.post(environment.baseUrl + '/authentication/citizen-log-in', {
      username,
      password
    });
  }

  signUp(username: string, password: string, cardCode: string): Observable<any> {
    return this.http.post(environment.baseUrl + '/authentication/register-master', {
      username,
      password,
      cardCode
    });
  }

  logout(): Observable<any> {
    return this.http.post(
      environment.baseUrl + '/authentication/log-out',
      null,
      {
        withCredentials: true
      });
  }
}
