import { Operator } from './operators.state';

export class CreateOperatorAction {
  static readonly type = '[Operators] Create operator';
  constructor(public username: string, public password: string) {}
}

export class FetchOperatorsAction {
  static readonly type = '[Operators] Fetch operators';
  constructor() {}
}

export class SelectOperatorAction {
  static readonly type = '[Operators] Select operator';
  constructor(public operator: Operator) {}
}

export class DeleteOperatorAction {
  static readonly type = '[Operators] Delete operator';
  constructor() {}
}

export class UpdateOperatorPassword {
  static readonly type = '[Operators] Update operator password';
  constructor(public password: string) {}
}

export class ClearOperators {
  static readonly type = '[Opearators] Clear';
  constructor() {}
}
