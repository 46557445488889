import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { get } from 'lodash';
import { ToastUtils } from 'src/app/shared/toast-utils';
import { Store } from '@ngxs/store';
import { LoginAction } from '../state/auth.actions';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';
import { CitLoadingController } from 'src/app/shared/loading.service';
import { environment } from 'src/environments/environment';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { JsonPipe } from '@angular/common';
import { SignInUtils } from './signin.modals';
import { CredentialsVault } from '../utils/credentialsVault.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  public loginForm: FormGroup;
  private loader?: any;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private store: Store,
    private toastCtrl: ToastController,
    public loadingController: CitLoadingController,
    private platform: Platform,
    private emailComposer: EmailComposer,
    private modalCtrl: ModalController,
    private credentialsVault: CredentialsVault,
  ) { }

  ngOnInit() {
    SplashScreen.hide();
    this.loginForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });

    this.tryAutofill();
  }

  async tryAutofill() {
    if(!this.platform.platforms().includes('capacitor')) {
      return;
    }

    const username = await this.credentialsVault.getUsername();
    if (username) {
      this.loginForm.patchValue({
        username,
      });
    }
  }


  async submit(force: boolean = false) {
    if(!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    await this.showLoading();
    const platformString = this.getPlatformString();
    this.store.dispatch(new LoginAction(
      this.loginForm.value.username,
      this.loginForm.value.password,
      platformString,
      force
    )).subscribe(()=> {
      this.hideLoading();
      this.credentialsVault.saveUsername(this.loginForm.value.username);
      this.router.navigateByUrl('/home', { replaceUrl: true });
    }, (error) => {
      this.hideLoading();
      this.showLoginError(error);
    });
  }

  async showLoginError(error) {
    if (error.error?.error === 'AlreadyLogged' && error.error?.session != null) {
      this.showSessionError(error.error?.session);
      return;
    }
    console.log(error);
    const errorCode = get(error, 'error.error', '');
    const errorMessage = errorCode === 'NotEnabled' ? 'login.error_not_enabled' :
                                            'login.error_generic';
    const message = await this.translateService.get(errorMessage).toPromise();
    const toast = await this.toastCtrl.create({
        ...ToastUtils.baseError,
        message
      });
    await toast.present();
  }

  async showSessionError(session) {
    const options = await SignInUtils.existingSession(this.translateService, session);
    const modal = await this.modalCtrl.create(options);
    modal.onDidDismiss().then((result) => {
      if (result.data === 0) {
        this.submit(true); // force login
      } else {
        // do nothing
      }
    });
    modal.present();
  }

  firstTimeLogin() {
    console.log('firstTimeLogin');
    this.router.navigateByUrl('/signup');
  }

  async forgotPassword() {
    const mail = environment.supportEmail;
    const subject = await this.translateService.get('email.forgot_subject').toPromise();
    const body = await this.translateService.get('email.forgot_body').toPromise();

    if (!this.platform.platforms().includes('capacitor')) {
      window.open(`mailto:${mail}?subject=${subject}&body=${body}`);
    } else {
      const email = {
        to: mail,
        subject,
        body,
        isHtml: true
      };
      // Send a text message using default options
      this.emailComposer.open(email);
    }
  }

  async showLoading() {
    this.loader = await this.loadingController.createLoding();
    this.loader.present();
  }

  hideLoading() {
    if (!this.loader) {
      return;
    }
    this.loader.dismiss();
  }

  getPlatformString(): string {
    if (!this.platform.is('hybrid')) {
      return 'Browser';
    }
    return this.platform.is('android') ? 'Android': 'iOS';
  }
}
