<div class="backdrop">
  <img class="backdrop-image">
  <div class="backdrop-solid"></div>
  <div class="backdrop-gradient"></div>
</div>
<ion-content>
  <form novalidate [formGroup]="signupForm" (ngSubmit)="submit()">
    <div class="container page-content">
      <div class="banner">
        <img class="logo"
              alt="Citizen logo"
              src="/assets/img/citizen.png">
        <div class="headings">
          <div class="title citizen-bold-label">{{ 'signup.title' | translate }}</div>
          <div class="subtitle citizen-light-label">{{ 'signup.description' | translate }}</div>
        </div>
      </div>

      <div class="inputs">
        <div class="citizen-label">{{ 'generic.username' | translate }}</div>
        <ion-input type="text"
                  class="username-input"
                  formControlName="username"
                  placeholder="{{ 'generic.username_placeholder' | translate }}"
                  ngDefaultControl></ion-input>
        <div class="citizen-label">{{ 'generic.password' | translate }}</div>
        <app-show-hide-password>
          <ion-input type="password"
                    formControlName="password"
                    placeholder="{{ 'generic.password_placeholder' | translate }}"
                    ngDefaultControl></ion-input>
        </app-show-hide-password>
                    
        <button class="forgot-password"
                type="button"
                (click)="forgotPassword()">{{ 'login.help' | translate }} <u>{{ 'login.help_click' | translate }}</u></button>          
      </div>

      <div class="controls">
        <button class="login-button" 
                type="button"
                (click)="submit()">{{ 'signup.signup_button' | translate }}</button>
        <div class="citizen-label first-access-label">{{ 'signup.back_to_login' | translate }}</div>
        <button class="auth-button"
                type="button"
                (click)="alreadyRegistered()">{{ 'signup.to_login' | translate }}</button>
      </div>
    </div>
  </form>
</ion-content>