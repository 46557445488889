import { ToastButton } from '@ionic/angular';

export class ToastUtils {

  static closeButton: ToastButton = {
    side: 'end',
    text: 'Chiudi',
    role: 'cancel'
  };

  public static baseError = {
    duration: 3000,
    color: 'danger',
    buttons: [
      ToastUtils.closeButton
    ]
  };
}
