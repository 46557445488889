import { Component, Input, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'home-tile',
  templateUrl: './home-tile.component.html',
  styleUrls: ['./home-tile.component.scss'],
})
export class HomeTileComponent implements OnInit {
  @Input() direction = '';
  @Input() labelText = '';
  @Input() imgSrc = '';
  @Input() background = 'black';
  @Input() showArrow = true;

  constructor() { }

  ngOnInit() { }

}
