<div class="container">
  <div class="header">
    <img class="close-button"
        alt="Chiudi"
        src="/assets/img/esc.png"
        (click)="close($event)">
    <div class="heading citizen-bold-label">{{scanCta}}</div>
  </div>
  <div class="space">
    <div class="viewfinder">
      <img src="/assets/img/mirino.png"
            alt="mirino">
    </div>
  </div>
  <div class="scan-footer">
    <button class="manual-input-button citizen-button"
            (click)="manualInput($event)">{{ 'scanner.manual_input_button' | translate }}</button>
  </div>
</div>