import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  @Input() title?: string;
  @Input() text?: string;
  @Input() cta: 'Chiudi';
  @Input() secondCta?: string;
  @Input() imgSrc?: string;

  constructor(
    private modalCtrl: ModalController,
    private nav: NavController,
    private platform: Platform,
  ) { }

  ngOnInit() {
    this.platform.backButton.subscribeWithPriority(9999, () => {
      this.closeModalOrPage();
    });
  }

  async closeModalOrPage(){
    const modal = await this.modalCtrl.getTop();
    if (modal){
      modal.dismiss();
    } else {
      this.nav.pop();
    }
  }

  close(event) {
    this.modalCtrl.dismiss(0);
  }

  secondClose(event) {
    this.modalCtrl.dismiss(1);
  }
}
