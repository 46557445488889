/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { App } from '@capacitor/app';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AuthState, User } from '../auth/state/auth.state';
import { HomeModals } from './home.modals';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { environment } from 'src/environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import packageJson from '../../../package.json';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FetchMyUser } from '../auth/state/auth.actions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @Select(AuthState.user) $user: Observable<User>;
  version = '';
  private sub?: Subscription;
  private appVersion = packageJson.version; // for web version

  constructor(
    private navCtrl: NavController,
    private modalCtlr: ModalController,
    private emailComposer: EmailComposer,
    private platform: Platform,
    private translateService: TranslateService,
    private store: Store,
  ) { }

  ngOnInit() {
    SplashScreen.hide();
    this.showVersion();

    if(localStorage.shouldCheckNFC === 'TRUE') {
      localStorage.shouldCheckNFC = 'FALSE';
      this.checkNFCTroubles();
    }

    this.store.dispatch(new FetchMyUser())
        .subscribe(
          () => {},
          (error) => {
            if (error?.error?.statusCode === 404) {
              this.navCtrl.navigateRoot('/signin');
            }
          });
  }

  checkNFCTroubles() {
    this.sub = this.$user
      .pipe(take(1))
      .subscribe(user => {
        if (user.nfcTroubles && !user.nfcExemption) {
          this.showNfcTroubleModal();
        }
      });
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  async showNfcTroubleModal() {
    if (!this.platform.platforms().includes('capacitor')) {
      return;
    }
    const options = await HomeModals.nfcTroubleWrite(this.translateService);
    const modal = await this.modalCtlr.create(options);
    modal.onDidDismiss().then((result) => {
      if (result.data === 0) {
        this.sendSupportMail();
      }
    });
    modal.present();
  }

  private showVersion() {
    App.getInfo().then((info) => {
      console.log(info);
      this.version = info.version + environment.versionSuffix;
    }).catch(() => {
      this.version = this.appVersion;
    });
  }

  private async sendSupportMail() {
    const mail = environment.supportEmail;
    const subject = await this.translateService.get('email.nfc_subject').toPromise();
    const body = await this.translateService.get('email.nfc_body').toPromise();

    if(!this.platform.platforms().includes('capacitor')) {
      window.open(`mailto:${mail}?subject=${subject}&body=${body}`);
    } else {
      const email = {
        to: environment.supportEmail,
        subject,
        body,
      };
      this.emailComposer.open(email);
    }
  }

  activateTapped() {
    if (!this.platform.platforms().includes('capacitor')) {
      this.navCtrl.navigateForward('warranties/activation/product-manual-input');
    } else {
      this.navCtrl.navigateForward('warranties');
    }
  }

  cancelTapped() {
    if (!this.platform.platforms().includes('capacitor')) {
      this.navCtrl.navigateForward('warranties/cancel/manual-input');
    } else {
      this.navCtrl.navigateForward('warranties/cancel/scan');
    }
  }

  productInfoTapped() {
    if (!this.platform.platforms().includes('capacitor')) {
      this.navCtrl.navigateForward('product/manual-input');
    } else {
      this.navCtrl.navigateForward('product');
    }
  }

  profileTapped() {
    this.navCtrl.navigateForward('profile');
  }

  operatorsTapped() {
    this.navCtrl.navigateForward('operators');
  }

  masterListTapped() {
    this.navCtrl.navigateForward('masters');
  }

  warrantyInfoTapped() {
    this.navCtrl.navigateForward('warranties/admin');
  }
}
