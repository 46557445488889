import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) { }

  getUserInfo(): Observable<any> {
    return this.http.get(
      environment.baseUrl + '/authentication/me',
      { withCredentials: true }
    );
  }

  reportNFCTroubles(): Observable<any> {
    return this.http.post(
      environment.baseUrl + '/authentication/me/report-nfc-troubles',
      {},
      { withCredentials: true }
    );
  }

  updatePassword(currentPassword: string, newPassword: string): Observable<any> {
    return this.http.post(
      environment.baseUrl + '/authentication/update-password',
      {
        currentPassword,
        newPassword,
      },
      { withCredentials: true }
    );
  }
}
