import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { ToastUtils } from '../shared/toast-utils';
import { Select, Store } from '@ngxs/store';
import { LogoutAction } from '../auth/state/auth.actions';
import { AuthState, User } from '../auth/state/auth.state';
import { Observable } from 'rxjs';
import { CitLoadingController } from '../shared/loading.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Select(AuthState.user) $user: Observable<User>;
  private loader?: any;

  constructor(
    private toastCtrl: ToastController,
    private store: Store,
    public loadingCrtl: CitLoadingController,
    private navCtrl: NavController,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() { }

  async logout() {
    this.loader = await this.loadingCrtl.createLoding();
    this.loader?.present();
    this.store.dispatch(new LogoutAction())
      .pipe(tap(() => this.loader?.dismiss()))
      .subscribe(() => {
        this.navCtrl.navigateRoot('/signin');
      }, (error) => {
        this.loader?.dismiss();
        this.showLogoutError(error);
      });
  }

  async showLogoutError(error) {
    const toast = await this.toastCtrl.create({
      ...ToastUtils.baseError,
      message: 'Errore durante il logout'
    });
    await toast.present();
  }

  async updatePasswordTapped() {
    this.navCtrl.navigateForward(['password'], { relativeTo: this.route });
  }
}
