<div class="container" 
    [ngClass]="{ 'row': direction === 'row' , 
                  'column': direction === 'column',
                  'black': background === 'black',
                  'grey': background === 'grey',
                  'highlight': background === 'highlight' }">
  <div class="icon">
    <img src="{{ imgSrc }}">
  </div>
  <div class="citizen-bold-label">{{ labelText }}</div>
  <div class="arrow-container">
    <img class="arrow" 
        [ngClass]="{ 'hide-arrow': !showArrow,
                      'highlight': background === 'highlight' }" 
        src="/assets/img/arrow.png">
  </div>
</div>
