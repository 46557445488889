import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ResetAction } from '../state/auth.actions';

/**
 * This interceptor detects 401 and 403 errors, which are returned when the user session is expired,
 * or when there's an unrecoverable error that needs a new sign-in.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private navCtrl: NavController,
    private loader: LoadingController,
    private store: Store
    ) { }

  public static isSessionExpError(error): boolean {
    return (error instanceof HttpErrorResponse &&
      (error.status === 401 || error.status === 403));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(async err => {
          if (AuthInterceptor.isSessionExpError(err)) {
                this.store.dispatch(new ResetAction()).subscribe();
                // try to dismiss a loader
                this.loader.dismiss();
                this.navCtrl.navigateRoot('/signin');
          }
          throw err;
          // return throwError(err);
        })
      );
  }
}
