import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { AuthService } from '../auth.service';
import { FetchMyUser, LoginAction, LogoutAction, ReportNFCTroubles, ResetAction } from './auth.actions';
import { mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProfileService } from 'src/app/profile/profile.service';
import { HttpClientXsrfModule } from '@angular/common/http';
import { ClearMaster } from 'src/app/masters/state/masters.actions';
import { ClearOperators } from 'src/app/operators/state/operators.actions';

export class User {
  public username: string;
  public role: string;
  public nfcExemption: boolean;
  public nfcTroubles: boolean;
  public cardCode: string;
}

@State<User>({
  name: 'auth',
  defaults: null
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private store: Store,
  ) {}

  @Selector()
  static user(state: User) {
    return state;
  }

  @Action(FetchMyUser)
  fetchMe(ctx: StateContext<User>) {
    return this.authService.me()
      .pipe(
        tap((user) => ctx.setState(user))
      );
  }

  @Action(LoginAction)
  login(ctx: StateContext<User>, action: LoginAction) {
    return this.authService.login(action.username, action.password, action.platform, action.force)
      .pipe(
        tap((user) => ctx.setState(user))
      );
  }

  @Action(ReportNFCTroubles)
  reportNfcTroubles(ctx: StateContext<User>) {
    return this.profileService.reportNFCTroubles()
      .pipe(
        tap((user) => ctx.setState(user))
      );
  }

  @Action(LogoutAction)
  logout(ctx: StateContext<User>, action: LogoutAction) {
    return this.authService.logout()
      .pipe(
        mergeMap(() => this.store.dispatch([ClearMaster, ClearOperators])),
        tap(() => ctx.setState(null))
      );
  }

  @Action(ResetAction)
  reset(ctx: StateContext<User>, action: ResetAction) {
    ctx.setState(null);
    return of({});
  }
}
