import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CompleteSignupComponent } from './auth/complete-signup/complete-signup.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileUpdatePasswordComponent } from './profile/update-password/profile-update-password.component';

const routes: Routes = [
  {
    path: 'onboarding',
    loadChildren: () =>  import('./onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  { path: 'signin', component: SigninComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'complete-registration', component: CompleteSignupComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'warranties',
    loadChildren: () => import('./warranties/warranties.module').then(m => m.WarrantiesModule)
  },
  {
    path: 'operators',
    loadChildren: () => import('./operators/operators.module').then(m => m.OperatorsModule)
  },
  {
    path: 'masters',
    loadChildren: () => import('./masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'profile/password',
    component: ProfileUpdatePasswordComponent
  },
  {
    path: '',
    redirectTo: '/onboarding',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
