<ion-content scroll-y="false">
    <div class="inner-content page-content">
      <div class="toolbar">
        <button class="profile-button"
        (click)="profileTapped()">
          <img src="/assets/img/profilo.png">
        </button>
      </div>
        <div class="citizen-light-label welcome-label">{{ 'home.hello' | translate }} {{ ($user | async)?.username }}</div>
        <div class="citizen-bold-label home-label">{{ 'home.title' | translate }}</div>
          <home-tile (click)="activateTapped()"
                      *ngIf="($user | async)?.role !== 'admin'"
                      direction="row"
                      background="highlight"
                      labelText="{{ 'home.activate_warranty' | translate }}"
                      imgSrc="/assets/img/attiva-garanzia-inverse.png"
                      class="product-block main-block"></home-tile>
          <home-tile (click)="cancelTapped()"
                      *ngIf="($user | async)?.role !== 'admin'"
                      direction="row"
                      labelText="{{ 'home.cancel_warranty' | translate }}"
                      imgSrc="/assets/img/annulla-garanzia.png"
                      class="product-block"></home-tile>
        
        <home-tile (click)="productInfoTapped()"
                  direction="row"
                  labelText="{{ 'home.search_product' | translate }}"
                  imgSrc="/assets/img/info-prodotto.png"
                  class="product-block"></home-tile>

        <home-tile (click)="warrantyInfoTapped()"
                  direction="row"
                  labelText="{{ 'home.search_warranty' | translate }}"
                  imgSrc="/assets/img/attiva-garanzia.png"
                  class="warranties-admin-block"
                  *ngIf="($user | async)?.role === 'admin'">
        </home-tile>

        <home-tile (click)="operatorsTapped()"
                  direction="row"
                  labelText="{{ 'home.manage_operators' | translate }}"
                  imgSrc="/assets/img/gestione-operatori.png"
                  class="product-block"
                  *ngIf="($user | async)?.role === 'master'"></home-tile>

        <home-tile (click)="masterListTapped()"
                  direction="row"
                  background="grey"
                  labelText="{{ 'masters.title' | translate }}"
                  imgSrc="/assets/img/gestione-operatori.png"
                  class="operators-block"
                  *ngIf="($user | async)?.role === 'admin'"></home-tile>
        
    </div>  
</ion-content>
<ion-footer collapse="fade">
  <div class="citizen-label">Versione: {{ version }}</div>
</ion-footer>
