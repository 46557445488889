import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninComponent } from './signin/signin.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from './signup/signup.component';
import { RegistrationState } from './state/registration.state';
import { NgxsModule } from '@ngxs/store';
import { CompleteSignupComponent } from './complete-signup/complete-signup.component';
import { AuthState } from './state/auth.state';
import { ProfileModule } from '../profile/profile.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    ProfileModule,
    TranslateModule,
    SharedModule,
    NgxsModule.forFeature([RegistrationState, AuthState])
  ],
  declarations: [
    SigninComponent, SignupComponent, CompleteSignupComponent
  ],
  providers: []
})
export class AuthModule { }
