import { Operator } from 'src/app/operators/state/operators.state';
import { Master } from './masters.state';

export class FetchMastersAction {
  static readonly type = '[Masters] Fetch masters';
  constructor() {}
}

export class SelectMasterAction {
  static readonly type = '[Masters] Select master';
  constructor(public master: Master) {}
}

export class UpdateMaxOperators {
  static readonly type = '[Masters] Update max operators count';
  constructor(public max: number) {}
}

export class ResetNFCTroubles {
  static readonly type = '[Masters] Reset NFC troubles';
  constructor() {}
}

export class ToggleNFCExemption {
  static readonly type = '[Masters] Toggle NFC exemption';
  constructor() {}
}

export class UpdateMasterPassword {
  static readonly type = '[Masters] Update password';
  constructor(public newPassord: string) {}
}

export class ResetOperatorNFCTroubles {
  static readonly type = '[Masters] Reset operator NFC troubles';
  constructor(public operator: Operator) {}
}

export class FetchSessionLogsAction {
  static readonly type = '[Masters] Fetch session logs';
  constructor() {}
}

export class ClearMaster {
  static readonly type = '[Masters] Clear';
  constructor() {}
}
