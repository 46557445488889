import { Component, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonRouterOutlet, ModalController, NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthState, User } from 'src/app/auth/state/auth.state';
import { FormUtilities } from 'src/app/shared/form-utilities';
import { CitLoadingController } from 'src/app/shared/loading.service';
import { ToastUtils } from 'src/app/shared/toast-utils';
import { ProfileService } from '../profile.service';
import { ProfileModals } from '../utils/profile.modals';

@Component({
  selector: 'app-profile-update-password',
  templateUrl: './profile-update-password.component.html',
  styleUrls: ['./profile-update-password.component.scss'],
})
export class ProfileUpdatePasswordComponent implements OnInit {
  @Select(AuthState.user) $user: Observable<User>;
  public updateForm: FormGroup;
  private loader?: any;

  constructor(
    public loadingCtrl: CitLoadingController,
    private store: Store,
    @Optional() private routerOutlet: IonRouterOutlet,
    private navCtrl: NavController,
    private profileService: ProfileService,
    private translateService: TranslateService,
    protected toastCtrl: ToastController,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.updateForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    }, FormUtilities.matchPasswordValidator);
  }

  async submit() {
    if(!this.updateForm.valid) {
      this.updateForm.markAllAsTouched();
      return;
    }

    this.loader = await this.loadingCtrl.createLoding();
    this.loader.present();
    this.profileService.updatePassword(
      this.updateForm.value.currentPassword,
      this.updateForm.value.password
      ).pipe(tap(() => this.loader?.dismiss()))
      .subscribe(() => {
        this.postUpdate();
      }, (error) => {
        this.loader?.dismiss();
        this.showError(error);
      });
  }

  async postUpdate() {
    const options = await ProfileModals.successUpdatePassword(this.translateService);
    const modal = await this.modalCtrl.create(options);
    modal.onDidDismiss().then(() => {
      this.navigateBack();
    });
    modal.present();
  }

  navigateBack() {
    if (this.routerOutlet && this.routerOutlet.canGoBack()) {
      console.log('top');
      this.navCtrl.setDirection('back');
      this.routerOutlet.pop();
    } else {
      console.log('bottom');
      this.navCtrl.navigateBack('/profile');
    }
  }

  async showError(error) {
    const errorCode = this.getErrorCode(error);
    const errorMessage = await this.translateService.get(errorCode).toPromise();
    const toast = await this.toastCtrl.create({
      ...ToastUtils.baseError,
      message: errorMessage
    });
    await toast.present();
  }

  getErrorCode(error): string {
    const errorCode = error?.error?.error || '';
    switch (errorCode) {
      case 'WrongCurrentPassword':
        return 'profile.wrong_current_password';
      case 'WeakPassword':
        return 'profile.weak_password';
      default:
        return 'generic.error';
    }
  }
}
