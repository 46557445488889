import { User } from './auth.state';

export class FetchMyUser {
  static readonly type = '[Auth] Fetch Me';
  constructor() {}
}

export class LoginAction {
  static readonly type = '[Auth] Login';
  constructor(public username: string, public password: string, public platform: string, public force: boolean = false) { }
}

export class ReportNFCTroubles {
  static readonly type = '[Warranties] Report NFC Troubles';
  constructor() { }
}

export class LogoutAction {
  static readonly type = '[Auth] Logout';
  constructor() { }
}

export class ResetAction {
  static readonly type = '[Auth] Reset';
  constructor() { }
}
