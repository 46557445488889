<div class="backdrop">
  <img class="backdrop-image">
  <div class="backdrop-solid"></div>
  <div class="backdrop-gradient"></div>
</div>
<ion-content>
  <form novalidate [formGroup]="signupForm" (ngSubmit)="submit()">
    <div class="container page-content">
      <div class="banner">
        <img class="logo"
              alt="Citizen logo"
              src="/assets/img/citizen.png">
        <div class="name citizen-bold-label">{{ 'signup.complete_registration_title' | translate }}</div>
      </div>

      <div class="inputs">
        <div class="citizen-label">{{ 'signup.new_password' | translate }}</div>
        <app-show-hide-password>
          <ion-input type="new-password"
                  class="username-input"
                  formControlName="newPassword"
                  placeholder="{{ 'signup.new_password' | translate }}"
                  ngDefaultControl></ion-input>
        </app-show-hide-password>
      </div>

      <div class="controls">
        <button class="confirm-button" 
                type="button"
                (click)="submit()">{{ 'signup.save' | translate }}</button>
      
        <button class="back-button"
                type="button"
                (click)="back()">{{ 'generic.back' | translate }}</button>
      </div>
    </div>
  </form>
</ion-content>