import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { AuthService } from '../auth.service';
import { CompleteRegistrationAction, StartRegistrationAction } from './registration.actions';
import { mergeMap, tap } from 'rxjs/operators/';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { LoginAction } from './auth.actions';

export class RegistrationStateModel {
  public username?: string;
  public cardCode?: string;
}

@State<RegistrationStateModel>({
  name: 'registration',
  defaults: {
    username: undefined,
    cardCode: undefined
  }
})
@Injectable()
export class RegistrationState {
  constructor(
    private authService: AuthService,
    private store: Store
    ) {}

  @Selector()
  static username(state: RegistrationStateModel) {
    return state.username;
  }

  @Action(StartRegistrationAction)
  start(ctx: StateContext<RegistrationStateModel>, action: StartRegistrationAction) {
    return this.authService.citizenLogin(action.username, action.password)
      .pipe(
        tap((response) => {
          console.log(response);
          ctx.patchState({
            username: action.username,
            cardCode: response.cardCode
          });
        })
      );
  }

  @Action(CompleteRegistrationAction)
  complete(ctx: StateContext<RegistrationStateModel>, action: CompleteRegistrationAction) {
    const username = ctx.getState().username;
    const cardCode = ctx.getState().cardCode;
    if (!username || !cardCode) {
      return throwError(() => new Error());
    }
    return this.authService.signUp(username, action.newPassword, cardCode)
      .pipe(
        mergeMap(() => this.store.dispatch(new LoginAction(username, action.newPassword, action.platform, true)))
      );
  }

}
