import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IonRouterOutlet, NavController, Platform, ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { CompleteRegistrationAction } from '../state/registration.actions';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { CitLoadingController } from 'src/app/shared/loading.service';

@Component({
  selector: 'app-complete-signup',
  templateUrl: './complete-signup.component.html',
  styleUrls: ['./complete-signup.component.scss'],
})
export class CompleteSignupComponent implements OnInit {
  public signupForm: FormGroup;
  private loader?: any;

  constructor(
    @Optional() private routerOutlet: IonRouterOutlet,
    private navCtrl: NavController,
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    private toastCtrl: ToastController,
    private translateService: TranslateService,
    public loadingController: CitLoadingController,
    private platform: Platform,
  ) { }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      newPassword: new FormControl('', [Validators.required])
    });
  }

  async submit() {
    if(!this.signupForm.valid) {
      this.signupForm.markAllAsTouched();
      return;
    }
    const platformString = this.getPlatformString();
    this.store.dispatch(new CompleteRegistrationAction(
      this.signupForm.value.newPassword, platformString
    )).subscribe(() => {
      this.router.navigateByUrl('/home', { replaceUrl: true });
    }, (error) => {
      this.showError(error);
    });
  }

  back() {
    if (this.routerOutlet && this.routerOutlet.canGoBack()) {
      this.navCtrl.setDirection('back');
      this.routerOutlet.pop();
    } else {
      this.navCtrl.navigateBack('/signin');
    }
  }

  async showError(error) {
    const errorMessage = await this.getErrorMessage(error);
    const toast = await this.toastCtrl.create({
      message: errorMessage,
      duration: 3000,
      color: 'danger',
      buttons: [
        {
          side: 'end',
          text: 'Chiudi',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async getErrorMessage(error): Promise<string> {
    const errorCode = get(error, 'error.error', '');
    let messageCode = 'signup.generic_error';
    switch (errorCode) {
      case 'WeakPassword':
        messageCode = 'signup.weak_password';
        break;
      case 'Conflict':
        messageCode = 'signup.conflict';
        break;
      default:
        messageCode = 'signup.generic_error';
    }
    return this.translateService.get(messageCode).toPromise();
  }

  async showLoading() {
    this.loader = await this.loadingController.createLoding();
    this.loader.present();
  }

  hideLoading() {
    if (!this.loader) {
      return;
    }
    this.loader.dismiss();
  }

  getPlatformString(): string {
    if (!this.platform.is('hybrid')) {
      return 'Browser';
    }
    return this.platform.is('android') ? 'Android': 'iOS';
  }
}
