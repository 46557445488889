import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export class FormUtilities {

  public static matchPasswordValidator(control: AbstractControl): ValidationErrors {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    if (!password || !confirmPassword) {
      return null;
    }
    if (password.value !== confirmPassword.value) {
      control.get('confirmPassword')?.setErrors(Validators.required);
      return { matchPassword: true };
    }
    return null;
  }

}

export class UsernameValidator {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
      if((control.value as string).indexOf(' ') >= 0) {
          return { cannotContainSpace: true };
      }

      return null;
  }
}
