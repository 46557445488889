<ion-content class="background">
  <div class="container">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/home"
                        text=""></ion-back-button>
      </ion-buttons>
      <ion-title>{{ 'profile.edit_password_title' | translate }}</ion-title>
    </ion-toolbar>

    <div class="name-header page-content">
      <div class="name-label citizen-label" 
          *ngIf="($user | async) as user">
        {{ user.username }}
      </div>
    </div>

    <form novalidate
          [formGroup]="updateForm"
          (ngSubmit)="submit()">
    <div class="form-content page-content">
      <div class="inputs">
        <div class="citizen-label">{{ 'profile.current_password' | translate }}</div>
        <app-show-hide-password>
          <ion-input type="password"
                formControlName="currentPassword"
                placeholder="{{ 'profile.current_password' | translate }}"
                ngDefaultControl
                autocomplete="off"></ion-input>
        </app-show-hide-password>

        <div class="citizen-label">{{ 'profile.new_password' | translate }}</div>
        <app-show-hide-password>
          <ion-input type="password"
                formControlName="password"
                placeholder="{{ 'profile.new_password' | translate }}"
                ngDefaultControl
                autocomplete="off"></ion-input>
        </app-show-hide-password>

        <div class="citizen-label">{{ 'profile.repeat_password' | translate }}</div>
        <app-show-hide-password>
          <ion-input type="password"
                formControlName="confirmPassword"
                placeholder="{{ 'profile.repeat_password' | translate }}"
                ngDefaultControl
                autocomplete="off"></ion-input>
        </app-show-hide-password>
      </div>

      <div class="controls">
        <button class="update-button citizen-button"
                type="button"
                (click)="submit()">{{ 'profile.update_password' | translate }}</button>
      </div>
    </div>
    </form>
  </div>
</ion-content>