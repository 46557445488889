import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
})
export class BarcodeScannerComponent implements OnInit {
  @Output()
  public manualInputTapped = new EventEmitter();
  @Output()
  public closeTapped = new EventEmitter();
  @Output()
  public scanResult = new EventEmitter();
  @Input()
  public scanCta = '';

  private scanning = false;

  constructor(
    private dialogs: Dialogs,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.startPermissionsFlow();
  }

  async startPermissionsFlow() {
    const result = await this.didUserGrantPermission();
    if (result === true) {
      this.startScan();
    } else {
      this.handlePermissionDenied();
    }
  }

  async didUserGrantPermission() {
    // check if user already granted permission
    const status = await BarcodeScanner.checkPermission({ force: false });

    if (status.granted) {
      // user granted permission
      return true;
    }

    if (status.denied) {
      // user denied permission
      return false;
    }

    if (status.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (status.neverAsked) {
      // user has not been requested this permission before
      // it is advised to show the user some sort of prompt
      // this way you will not waste your only chance to ask for the permission
      const title = await this.translateService.get('scanner.permission_dialog_title').toPromise();
      const text = await this.translateService.get('scanner.permission_dialog_text').toPromise();
      const result = await this.dialogs.confirm(
        text,
        title, ['ok', 'annulla']);
      if (result !== 1) {
        return false;
      }
    }

    if (status.restricted || status.unknown) {
      // ios only
      // probably means the permission has been denied
      return false;
    }

    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await BarcodeScanner.checkPermission({ force: true });

    if (statusRequest.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (statusRequest.granted) {
      // the user did grant the permission now
      return true;
    }

    // user did not grant the permission, so he must have declined the request
    return false;
  }

  async startScan() {
    this.scanning = true;
    await BarcodeScanner.hideBackground(); // make background of WebView transparent

    const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

    // if the result has content
    if (result.hasContent) {
      console.log(result.content); // log the raw scanned content
      this.scanResult.emit(result.content);
    }
  }

  async handlePermissionDenied() {
    const title = await this.translateService.get('scanner.permission_dialog_title').toPromise();
    const text = await this.translateService.get('scanner.permission_dialog_text').toPromise();
    const result = await this.dialogs.confirm(
                        text,
                        title, ['ok', 'annulla']);
    // result is the 1-based index of the button
    if (result === 1) {
      BarcodeScanner.openAppSettings();
    }
  }

  resume() {
    if (this.scanning) {
      this.startScan();
    }
  }

  manualInput(event) {
    this.manualInputTapped.emit(event);
  }

  close(event) {
    this.closeTapped.emit(event);
  }
}
