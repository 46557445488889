import { ModalOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HelpComponent } from 'src/app/shared/help/help.component';

export class ProfileModals {

  public static async successUpdatePassword(translateService: TranslateService): Promise<ModalOptions> {
    const title = await translateService.get('profile.update_password_success_title').toPromise();
    const text = await translateService.get('profile.update_password_success_text').toPromise();
    const cta = await translateService.get('profile.update_password_success_button').toPromise();
    return {
      component: HelpComponent,
      componentProps: {
        title,
        text,
        imgSrc: 'assets/img/operatore-creato.png',
        cta
      },
      backdropDismiss: false,
      showBackdrop: true,
    };
  }
}
