<ion-content >
  <div class="background">
  <div class="container">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/home"
                          text=""></ion-back-button>
      </ion-buttons>
      <ion-title>{{ 'profile.title' | translate }}</ion-title>
    </ion-toolbar>

    <div class="profile page-content">
      <div class="role-label citizen-label">{{ 'profile.role' | translate }}</div>
      <div class="info-container">
        <div class="citizen-label userinfo-label">{{ ($user | async)?.role }}</div>
      </div>

      <div class="username-label citizen-label">{{ 'profile.username' | translate }}</div>
      <div class="info-container">
        <div class="citizen-label userinfo-label">{{ ($user | async)?.username }}</div>
      </div>

      <ion-button class="update-pass-button citizen-button"
            (click)="updatePasswordTapped()"
            *ngIf="($user | async)?.role === 'master' || ($user | async)?.role === 'admin'">{{ 'profile.update_password' | translate }}</ion-button>
    </div>

  </div>
</div>
</ion-content>
<ion-footer>
  <div class="footer-container">
    <button (click)="logout()">{{ 'profile.logout' | translate }}</button>
  </div>
</ion-footer>
