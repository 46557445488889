import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeTileComponent } from './home-tile/home-tile.component';
import { HomeComponent } from './home.component';
import {EmailComposer} from '@awesome-cordova-plugins/email-composer/ngx';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    HomeComponent,
    HomeTileComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  providers: [
    EmailComposer
  ]
})
export class HomeModule { }
